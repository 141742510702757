import { useMemo, useState } from "react";
import NavigationBar from "../components/NavigationBar";
import RenderInstance from "../renderer/Renderer";
import AboutMeView from "./AboutMeView";
import ContactView from "./ContactView";
import HomeView from "./HomeView";
import ProjectView from "./ProjectView";
import GalaxyView from "./GalaxyView";
import GalaxyView2 from "./GalaxyView2";

export type ViewType = "HOME" | "ABOUT_ME" | "PROJECTS" | "CONTACT" | "GALAXY" | "GALAXY2";

export default function ViewHandler() {
  // Set initial view to HOME
  const [currentViewType, setView] = useState<ViewType>("HOME");

  const renderer = useMemo(() => {
    return new RenderInstance();
  }, []);

  const menuButtons = useMemo(() => {
    return (
      <NavigationBar
        changeView={(newView) => {
          setView(newView);
        }}
      />
    );
  }, []);

  const CurrentView = useMemo(() => {
    switch (currentViewType) {
      case "HOME":
        return <HomeView navigationBar={menuButtons} renderer={renderer} />;
      case "ABOUT_ME":
        return <AboutMeView navigationBar={menuButtons} renderer={renderer} />;
      case "PROJECTS":
        return <ProjectView navigationBar={menuButtons} renderer={renderer} />;
      case "CONTACT":
        return <ContactView navigationBar={menuButtons} renderer={renderer} />;
      case "GALAXY":
        return <GalaxyView navigationBar={menuButtons} renderer={renderer} />;
      // case "GALAXY2":
      //   return <GalaxyView2 navigationBar={menuButtons} renderer={renderer} />;
    }
  }, [currentViewType, menuButtons, renderer]);

  return <div style={{ width: "100%", height: "100%" }}>{CurrentView}</div>;
}
