import {
  Backdrop,
  Button,
  Card,
  CardContent,
  Dialog,
  Fade,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import styled from "styled-components";
import ContactItem from "../components/ContactItem";
import PlanetRenderInstance from "../renderer/PlanetRenderer";
import RenderInstance from "../renderer/Renderer";

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #414141; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #2d0d15 0%,
    #663b3b 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #2d0d15 0%,
    #663b3b 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #2d0d15 0%,
    #663b3b 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
`;

const PGPKey = `
-----BEGIN PGP PUBLIC KEY BLOCK-----
mQGNBGE+ahQBDACtU7puf2DbxV8VuoiW/jXLvlZKJ1clbFugO+SyeD6rWyzkvPMq
JMSl2UbYIHdxt0CBQgLMf6+ZC7kxKPwiBuwjQ+PuP2L3Oj7kCXf3/8FAK/KK1NsN
Q8Drfr/4+VCK3UoFUoOQHX0g+yXX/elhWUm+dCw/iX4iX7v+u2MNlGTPbhihPVez
kf/UhjEPyAj/Yf1Z4cSQmMvCCH++ySyB9oF+x7vaHXslhansnjxiNha8iDJdkbkr
Tzgl1D59Jc2dGAh0hzmxg5Xtud6Nskau7iBIlNKbI5EGjo2w+lCyylag2e16gKZg
iiQJScngjVFvC767++oSaRYML3kBUzo2vO8Vb2V9VJ2h9jbsrhbtxVhHdiIDABfo
Ab+e1iUDWJcL2ruUIbXLEkcCECLxcjtljIayU9mg07ezh1+Jy42SLdHDtgSd/DAf
qkPonfN0GA81j7XSOcJAbJYoh5dvG3gTdGJyQKbgB7XqTFv4UKD6TohYhO48DBmE
POsWjriWY7wGp8UAEQEAAbQuUm9iZXJ0IFNjaGFmZmVucmF0aCA8cm9iZXJ0QHNj
aGFmZmVucmF0aC5pbmZvPokBzgQTAQoAOAIbAwULCQgHAgYVCgkICwIEFgIDAQIe
AQIXgBYhBEZJJdBGTvj04SxqWv7x8pPk5XidBQJhPmuJAAoJEP7x8pPk5XidvfYL
/0qYzmFdCMcatzSZxAxUMn/G4/+PSoVsL2JOP0csj/dW3EIWF3cGkqhjmeL7C+bw
+b+JxcyiQIZsGPeHZDDspP3GE1je3Hdr/MFiihVKoa8Dw1cUk82yssj/PKFPw+kM
/kgellKlyJE2k0iFjzUbUqnAeBxXMd7o61Dkrwcr0aTf07cFBCTsC3vgasvEefvS
5C2wf4VI7wtZuOs45nsohC/e2BaSC5n7GWElrnVgXWjyF2ctYoOBIRmO33Zdvr30
ej57MoOQC5PeG6fFvlL3t2vLZVMwKYnQYB7UCrRbpWuEyel9pjSe1Al3Ime3tKMN
cgYvCPygDeltMuX6KRb9bFJdBPoSh1vAPer4foVs+0bbJw/pDmTzFgQl1EKfutSW
VWF757LY+zpIeA9yFldg0d28/MvWnQx0FUxpnuQOLXSpQ+1rfIn1NcCP9Nuu4xPz
9yhZHUb+QB3iFWgozAShvqqCWtyMYzVjx5H2Mlax/jo8iKRnC031NpV0gRW4JLzt
N7kBjQRhPmoUAQwAqCzkDNY1xannHIOIqcLMLrvC4xJ76MQehJoHUOm5Fou71DHC
2fhpk+uFxokAzQcFvYFlA6W6okXdHgGIeWQVjG4dJQxJVE6aTS9LZ7F1UsFh5Bh8
19C4FbeRSQXdjt1rwrczcOZ5P8gQvtVTixPDqDgsH0bGYIgJALBwYfTkIThmgXmr
lTPMeGXyEgAsHomPzFyX9mLup7/0gjWX+YHr8VF05V8fxyqAQrhNrEvoP0vZqCAe
tlGC8jEb0VxHtVIU/Pav1BhDOI3X0iWaltRcRe+3vT3BG9uRHWr6QpckWJCCr/Gp
E6c8OxEHlbtskeKIWVRPwcgqWmAfeVuEj68zv1pdKjNDUa0zIkf6xt7TaIr512pQ
xIPhqMc3EcdNcZ6H9qDgVj6kFMQ41qAf8JU8Erf8vd/93xWAUrvN/9dH7C1Ht5x5
GS/dMGDAIZTz8r4FhweFPbN8VIBVsb7Cq6nONABOL6hPvRXDeqVieoxCMXaetUP6
xr4rrfufW1sNUYajABEBAAGJAbwEGAEKACYWIQRGSSXQRk749OEsalr+8fKT5OV4
nQUCYT5qFAIbDAUJA8JnAAAKCRD+8fKT5OV4nVxYC/9O+a9c7FS5JwZConalgMHj
V9Qw6yRoyEAsK+pNU0jaRU53+25QDOokcB1rG4If0dQt/3qsLqfk/yXAoQBLNn9P
K0TYTQTOAtbpjn5SUkP9ibw6TFFxky1xQQ6GFMml0B/o6Nk9P2CyseVqlkxEtM/f
Pet5m9nW5EsPoYzfiJ5MP16wVqLyv3+177RfshdE/3YCwEB1kjxR3bJ/fs6FcUkn
xHuJeZFKt4YPmjSgt4D4pYKkak2JivsMgdj9iIZutiPRGO7Ky1AiDo2dIi+IRHCL
wb4StsXyogUYzKIk12HsFSd+KeHD3Tc554eRRUj/V8ZKX8xlxuty6FpQi8yKMO9p
6nk1SP2ZI6Znyu1aKf6J2vA/hmpquyfXn+8yZFo1RcKhobtMnA+fWSbQ+XvVOWZ0
n8GLotLCmXgFZwHMT+bjSRWmemepFQEpKAoqPOGDqz6UdMwBfj/eVLt7lRhHJ2Ge
68FAAf8XiEV95ckxReGhinFqZfFdIohwBf4uvtFqOUg= =Uz7f
-----END PGP PUBLIC KEY BLOCK-----`;

interface Props {
  navigationBar: JSX.Element;
  renderer: RenderInstance;
}

export default function ContactView(props: Props) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCopied, setCopied] = useState<boolean>(false);

  return (
    <Background>
      <div style={{ position: "fixed" }}>
        <PlanetRenderInstance renderer={props.renderer.getRenderInstance()} />
        {props.navigationBar}
      </div>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="baseline"
        style={{ position: "fixed", bottom: "150px" }}
      >
        <Grid item xs={3}>
          <ContactItem
            type="Phone"
            text="+43 681 81212024"
            symbol="static/phone.svg"
          />
        </Grid>
        <Grid item xs={4}>
          <ContactItem
            type="E-Mail"
            text="robert@schaffenrath.info"
            symbol="static/mail.svg"
          />
        </Grid>
        <Grid item xs={1} style={{}}>
          <ContactItem
            type="PGP"
            text="PGP"
            symbol="static/key.svg"
            showModal={setShowModal}
          />
        </Grid>
        <Grid item xs={3}>
          <ContactItem
            type="LinkedIn"
            text="LinkedIn Profile"
            symbol="static/linkedin.svg"
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: "20px" }}>
          <ContactItem
            type="Vaccine"
            text="VACCINATION CERTIFICATE"
            symbol="static/vaccine.svg"
          />
        </Grid>
      </Grid>
      <Dialog
        aria-labelledby="GPG-modal"
        aria-roledescription="Modal-showing-GPG-public-key"
        open={showModal}
        onClose={() => setShowModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={showModal}>
          <Card
            style={{
              boxShadow: "5",
              backgroundColor: "#f0f0f0e0",
              fontFamily: "fantasy",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                component="h2"
                style={{ marginBottom: "10px", fontFamily: "Urbanist" }}
              >
                PGP Key
              </Typography>
              <Typography
                variant="body2"
                component="p"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {PGPKey}
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  navigator.clipboard.writeText(PGPKey);
                  setCopied(true);
                }}
                style={{
                  marginTop: "20px",
                  backgroundColor: "#999999",
                  border: "1px solid",
                  borderColor: "darkblue",
                }}
              >
                Copy
              </Button>
              <Snackbar
                open={showCopied}
                autoHideDuration={2000}
                onClose={() => setCopied(false)}
              >
                <Alert severity="success">
                  PGP successfully copied to clipboard.
                </Alert>
              </Snackbar>
            </CardContent>
          </Card>
        </Fade>
      </Dialog>
    </Background>
  );
}
