import * as THREE from "three";

export class GeneralRender {
  private camera: THREE.PerspectiveCamera;
  private renderer: THREE.WebGLRenderer;
  private scene: THREE.Scene;
  private animationFrameId: number = -1;

  constructor() {
    // Init renderer, camera and scene
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.renderer.setClearColor(0x000000, 0.0);
    this.camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      1,
      350
    );
    this.camera.position.z = 200;
    this.camera.position.y = -15;
    this.scene = new THREE.Scene();

    window.addEventListener("resize", this.updateCameraOnResize);
  }

  getCamera = () => {
    return this.camera;
  };

  getRenderer = () => {
    return this.renderer;
  };

  getScene = () => {
    return this.scene;
  };

  updateCameraOnResize = () => {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
  };

  animate = (renderFunc: () => void) => {
    this.animationFrameId = requestAnimationFrame(() =>
      this.animate(renderFunc)
    );
    renderFunc();
  };

  updateAnimationFrameId = (newId: number) => {
    this.animationFrameId = newId;
  };

  resetScene = () => {
    // Reset Camera
    this.camera.fov = 75;
    this.camera.rotation.set(0, 0, 0);
    this.camera.position.set(0, -15, 200);
    this.camera.updateProjectionMatrix();

    // Cancel the previous animation if still running
    if (this.animationFrameId !== -1)
      cancelAnimationFrame(this.animationFrameId);

    // Remove all elements from previous scene
    while (this.scene.children.length > 0)
      this.scene.remove(this.scene.children[0]);
  };

  renderScene = () => {
    this.renderer.render(this.scene, this.camera);
  };
}

export default class RenderInstance {
  private renderInstance: GeneralRender | undefined = undefined;

  getRenderInstance = () => {
    if (this.renderInstance === undefined) {
      this.renderInstance = new GeneralRender();
    }

    return this.renderInstance;
  };
}
