import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import { useState } from "react";

export type ContactType = "E-Mail" | "Phone" | "PGP" | "LinkedIn" | "Vaccine";

interface Props {
  type: ContactType;
  text: string;
  symbol: string;
  showModal?: (showModal: boolean) => void;
}

export default function ContactItem(props: Props) {
  const [isMouseOver, setMouseOver] = useState<boolean>(false);
  return (
    <Card
      style={{
        display: "flex",
        border: "1px solid",
        borderColor: "#adadad",
        borderRadius: "5px",
        background: `${isMouseOver ? "#8f8f8fc6" : "#b3b3b321"}`,
        cursor: "pointer",
      }}
      onMouseOver={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
      onClick={(e) => {
        e.preventDefault();
        if (props.type === "E-Mail") {
          window.open(`mailto:${props.text}`);
        } else if (props.type === "Phone") {
          window.open(`tel:${props.text}`);
        } else if (props.type === "LinkedIn") {
          window.open(
            `https://www.linkedin.com/in/robert-schaffenrath-780992217/`
          );
        } else if (props.type === "Vaccine") {
          window.open(`static/Impfzertifikat.pdf`);
        } else if (props.showModal !== undefined) {
          props.showModal(true);
        }
      }}
    >
      <CardMedia
        component="img"
        alt={props.type}
        title={props.type}
        image={props.symbol}
        style={{
          width: "40px",
          height: "40px",
          objectFit: "fill",
          verticalAlign: "center",
          margin: "auto 5px auto 5px",
          left: 0,
        }}
      />
      <CardContent style={{ width: "100%", padding: "5px 0px 5px 0px" }}>
        <Typography
          component="p"
          variant="overline"
          align="right"
          style={{
            width: "100%",
            color: "#f5f5f5",
            textAlign: "center",
            margin: "auto",
            fontSize: "16px",
            fontFamily: "Urbanist",
          }}
        >
          {props.text}
        </Typography>
      </CardContent>
    </Card>
  );
}
