import styled from "styled-components";

const CustomButton = styled.div`
  display: inline-block;
  width: 180px;
  height: 50px;
  line-height: 50px;
  font-family: Helvetica;
  letter-spacing: 0.1em;
  font-weight: 100;
  font-size: 1.8em;
  text-align: center;
  color: #b9b9b9;
  cursor: pointer;

  border-bottom: 1px solid;

  transition: margin-top 200ms;
  margin-top: 0px;
  &:hover {
    color: #ebebeb;
    margin-top: 5px;
  }
`;

interface Props {
  text: string;
  targetLocation: () => void;
}

export default function MenuButton(props: Props) {
  return (
    <CustomButton onMouseUp={props.targetLocation}>{props.text}</CustomButton>
  );
}
