import { Grid, Paper, Typography } from "@material-ui/core";

export type Language = "German" | "English";

interface Props {
  currentLanguage: Language;
  setLanguage: (lang: Language) => void;
}

export default function LanguageButton(props: Props) {
  return (
    <div
      style={{
        position: "fixed",
        top: "200px",
        right: "80px",
        width: "120px",
        height: "30px",
        justifyContent: "center",
        border: "1px solid",
        borderRadius: "2px",
        borderColor: "#b9b9b9",
        boxShadow: "3px 3px #ffffff30",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          margin: "auto",
          width: "100%",
          height: "100%",
        }}
      >
        <Grid item xs={6}>
          <Paper
            square
            variant="outlined"
            style={{
              background: "#ffffff00",
              cursor: "pointer",
              borderRadius: "2px",
              borderColor:
                props.currentLanguage === "English" ? "#b9b9b9" : "#ffffff00",
              width: "80%",
              margin: "auto",
            }}
            onClick={() => props.setLanguage("English")}
          >
            <Typography
              variant="body1"
              component="p"
              style={{ color: "#b9b9b9", textAlign: "center" }}
            >
              EN
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            square
            variant="outlined"
            style={{
              background: "#ffffff00",
              cursor: "pointer",
              borderRadius: "2px",
              borderColor:
                props.currentLanguage === "German" ? "#b9b9b9" : "#ffffff00",
              width: "80%",
              margin: "auto",
            }}
            onClick={() => props.setLanguage("German")}
          >
            <Typography
              variant="body1"
              component="p"
              style={{ color: "#b9b9b9", textAlign: "center" }}
            >
              DE
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
