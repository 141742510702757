import { Typography } from "@material-ui/core";

export function MOTIVATION_EN() {
  return (
    <Typography
      variant="body1"
      component="p"
      style={{ color: "white", fontFamily: "Urbanist", fontSize: "18px" }}
    >
      When I was around eight or nine years old I already destroyed three
      computers of my parents. These machines were such a great mystery. How
      could current and voltage from a power outlet be transformed into an image
      on a screen that can be interacted with? It seemed like magic and I was so
      curious to find out how it works. After secondary school I attended HTL
      for electrical engineering to learn the fundamentals of electrical power,
      but it was clear that I wanted to do something more IT related and
      therefore started to learn my first programming language{" "}
      <em>(Objective-C)</em> on my own and got familiar with Linux. <br />
      During the first semesters of my computer science studies, I was a bit
      overwhelmed by the vast range of related topics, probably not many people
      know beforehand. Although some of these subjects were quite exhausting at
      the time, they conveyed core aspects of computer science and I got many
      new insights that I would have never encountered or would not have even
      known they existed otherwise. Theoretical understanding is of course
      important, but I knew my passion is more on the implementation side and
      therefore I enjoyed the practical lessons way more. <br />
      Since operating systems always fascinated me, I was fortunate enough to do
      my bachelor's thesis about simulating and visualizing some operating
      system services. <br />
      The master's studies were way better than anticipated. Doing bigger
      projects, building on the fundamentals of the bachelor's courses and
      learning about advanced topics was awesome, and I am extremely glad that I
      decided to continue my education at that point. I was also given the
      opportunity to do my master's thesis in form of a project employment and
      was asked to teach students in the subjects <em>
        Operating Systems
      </em> and <em>Parallel Programming</em>, which was an amazing experience
      and I really enjoyed sharing the stuff I learned over the years with other
      students.
    </Typography>
  );
}

export function MOTIVATION_DE() {
  return (
    <Typography
      variant="body1"
      component="p"
      style={{ color: "white", fontFamily: "Urbanist", fontSize: "18px" }}
    >
      Als ich acht oder neun Jahre alt war, habe ich bereits drei Computer
      meiner Eltern zerstört. Diese faszinierenden Boxen waren ein riesiges
      Mysterium. Wie kann aus Spannung und Strom einer einfachen Steckdose ein
      Bild entstehen, mit dem beliebig interagiert werden kann? Es erschien wie
      Magie und ich wollte unbedingt verstehen, wie so etwas funktionieren kann.
      Nach meiner Hauptschulzeit besuchte ich die HTL für Elektrotechnik um die
      Grundlagen von elektrischer Leistung zu lernen. Allerdings war schon sehr
      früh klar, dass ich später etwas im IT-Bereich machen will. Deshalb begann
      ich meine erste Programmiersprache <em>(Objective_-C</em> zu lernen und
      machte mich mit Linux vertraut.
      <br />
      Das erste Semester Informatik war ziemlich überraschend, denn erst dort
      bemerkte ich die riesige Menge an verwandten Themen, welche allgemein
      Vermutlich nicht allzu bekannt sind. Obwohl einige der Fächer relativ
      anstrengend waren und damals weniger relevant erschienen als andere, bin
      ich dennoch froh sie kennengelernt zu haben, da sie grundlegende Aspekte
      der Informatik beinhalten. Theoretisches Verständnis ist essenziell, aber
      bereits sehr früh bemerkte ich, dass meine Leidenschaft mehr in den
      praktischen Bereichen liegt, weshalb mir solche Fächer deutlich mehr
      Freude bereiteten. Das Thema Betriebssysteme fand ich schon immer sehr
      faszinierend und hatte das große Glück meine Bachelorarbeit zu diesem
      Thema schreiben zu dürfen.
      <br />
      Das Masterstudium war deutlich besser als erwartet. Arbeiten an größeren
      Projekten, auf dem Fundament des im Bachelor gelernten aufzubauen und mit
      komplexeren Themen konfrontiert zu werden, war großartig und ich bin sehr
      froh, dass ich meine Ausbildung an diesem Punkt fortgesetzt habe. Mir
      wurde später von Professoren angeboten, meine Masterarbeit im Umfang einer
      Projektanstellung zu machen und Bachelorstudenten in den Fächern{" "}
      <em>Betriebssystem</em> und <em>Parallele Programmierung</em> zu
      unterrichten. Dies war eine außergewöhnliche Erfahrung und ich bin sehr
      Dankbar das mir diese Möglichkeit geboten wurde.
    </Typography>
  );
}

export function INTERESTS_EN() {
  return (
    <Typography
      variant="body1"
      component="p"
      style={{ color: "white", fontFamily: "Urbanist", fontSize: "18px" }}
    >
      Since the curriculum could obviously not cover all areas I was interested
      in, I tried to challenge myself by learning new things in my spare time
      and choosing projects with unfamiliar requirements. Thereby I taught
      myself a bit web development, played around with my Raspberry Pi and got
      familiar with frameworks like Qt, Android Studio and React among other
      things. It is always exciting to see how similar problems are solved
      differently to learn from various approaches and improve your own skills.
    </Typography>
  );
}
export function INTERESTS_DE() {
  return (
    <Typography
      variant="body1"
      component="p"
      style={{ color: "white", fontFamily: "Urbanist", fontSize: "18px" }}
    >
      Da das Curriculum natürlich nicht alle Themen die mich interessierten in
      der gegebenen Zeit behandeln konnte, versuchte ich in meiner Freizeit neue
      Aspekte zu lernen und absichtlich Themen für Arbeiten zu wählen, welche
      mir bis dato unbekannt waren. Dadurch brachte ich mir ein wenig Web
      Development bei, spielte mit meinem Raspberry Pi und machte mich mit
      einigen Framework vertraut, wozu auch Qt, Android Studio und React zählen.
    </Typography>
  );
}
export function SPARE_TIME_EN() {
  return (
    <Typography
      variant="body1"
      component="p"
      style={{ color: "white", fontFamily: "Urbanist", fontSize: "18px" }}
    >
      Growing up in Tirol Austria was incredible. Having mountains directly in
      front of your house gives you several paths to discover whenever you want.
      Therefore, I spend countless hours outside hiking higher and higher
      summits. Especially during my time at university, where I spend most of my
      days in front of a monitor, the importance of a balanced lifestyle became
      more noticeable. Having a healthy diet, going for a run once or twice a
      week and doing a longer bike tour every so often is a big part of my life
      that I would not like to change.
    </Typography>
  );
}

export function SPARE_TIME_DE() {
  return (
    <Typography
      variant="body1"
      component="p"
      style={{ color: "white", fontFamily: "Urbanist", fontSize: "18px" }}
    >
      In Tirol aufzuwachsen war für mich perfekt. Direkt einen Berg vor der
      Haustüre zu haben, war sehr motivierend neue Wege zu erkunden und immer
      weiterzukommen. Ich verbrachte unzählige Stunden mit wandern und versuchte
      höhere und höhere Gipfel zu besteigen. Besonders in der Uni-Zeit, wo der
      Großteil des Tages vor einem Bildschirm verbracht wurde, ist die
      Wichtigkeit von Sport nochmals deutlicher geworden. Auf eine gesunde
      Ernährung zu achten, ein oder zweimal in der Woche laufen zu gehen und ab
      und zu eine längere Radtour zu machen sind sehr wichtige Aspekte in meinem
      Leben, welche ich auch nicht ändern möchte.
    </Typography>
  );
}

export function PHILOSOPHY_EN() {
  return (
    <Typography
      variant="body1"
      component="p"
      style={{ color: "white", fontFamily: "Urbanist", fontSize: "18px" }}
    >
      One of my biggest issues was trying to do everything on my own. Before I
      asked someone else for a little help, I rather spend multiple hours to
      solve a problem by myself. This has made many circumstance way more
      difficult than they should have been. Thankfully, I have grown in the last
      couple of years and realized the importance of teamwork and helping each
      other out, such that in retrospect my behaviour seemed quite silly.
      Though, I am still far from perfect and try to work on myself
      continuously, which is why I appreciate opinions and critic of friends and
      colleagues a lot. <br />
    </Typography>
  );
}

export function PHILOSOPHY_DE() {
  return (
    <Typography
      variant="body1"
      component="p"
      style={{ color: "white", fontFamily: "Urbanist", fontSize: "18px" }}
    >
      Ein bedeutendes Problem, mit dem ich länger gekämpft habe, war das
      Verlangen alles allein bewerkstelligen zu können. Bevor ich jemanden um
      Hilfe gebeten hätte, wäre ich mehrere Stunden an einem Problem gesessen,
      um allein eine Lösung zu finden. Diese Einstellung hat bestimmt sehr viele
      Situationen um einiges schwieriger gemacht als notwendig gewesen wäre. In
      den letzten paar Jahren bin ich glücklicherweise gewachsen und habe
      gemerkt, dass eine solche Einstellung ziemlich dumm, arrogant und nicht
      zielführend ist. Durch die Erkenntnis, wie wichtig Teamarbeit und einander
      zu helfen ist, erscheint mein Verhalten in viele Situationen rückblickend
      als ziemlich albern. Daher sind mir Meinungen und Kritik von Freunden und
      Kollegen äußerst wichtig und ich schätze diese sehr.
    </Typography>
  );
}

export function END_EN() {
  return (
    <Typography
      variant="body1"
      component="p"
      style={{ color: "white", fontFamily: "Urbanist", fontSize: "18px" }}
    >
      If you made it this far, I want to thank you for taking the time to learn
      a bit about me. This site is a side project of mine, where I wanted to
      play around with WebGL and see if I could create something visually
      interesting. It is by far not flawless and lacks some common features. On
      the other side, it does not require cookies and does not track users in
      any way. <br />
      If you want to talk or maybe just share some ideas, just send me an
      e-mail. I'm looking forward to your message.
    </Typography>
  );
}

export function END_DE() {
  return (
    <Typography
      variant="body1"
      component="p"
      style={{ color: "white", fontFamily: "Urbanist", fontSize: "18px" }}
    >
      Wenn du es bis hierher geschaffst hast, möchte ich dir danken, dass du dir
      die Zeit genommen hast ein wenig über mich zu lernen. Diese Website ist
      nur ein kleines Nebenprojekt von mir, wo ich ein wenig mit WegGL
      herumspielen und etwas besser in Web-Development werden wollte, obwohl
      letzteres jetzt nicht zu meinen größten Leidenschaften zählt. Diese Seite
      ist bei weitem nicht perfekt und ich habe noch unzählige Ideen sie zu
      verbessern. Es werden hier keine Cookies benötigt und Nutzer werden auch
      nicht getrackt. <br />
      Wenn du über mögliche Projekte reden oder ein paar Ideen austauschen
      willst, schreib mir einfach eine E-Mail.
    </Typography>
  );
}
