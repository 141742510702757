import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";
import { useState } from "react";

interface Props {
  color: string;
  image: string;
  title: string;
  description: string;
  link: string;
}

export default function ProjectItem(props: Props) {
  const [isMouseOver, setMouseOver] = useState<boolean>(false);
  return (
    <Card
      style={{
        display: "flex",
        cursor: "pointer",
        border: "1px solid",
        borderColor: "#adadad",
        background: `${isMouseOver ? "#8f8f8fc6" : "#b3b3b321"}`,
        marginTop: "20px",
        borderRadius: "5px",
      }}
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onClick={(e) => {
        e.preventDefault();
        window.open(props.link);
      }}
    >
      <CardMedia
        component="img"
        alt={props.title}
        title={props.title}
        image={props.image}
        style={{
          width: "50px",
          height: "50px",
          objectFit: "fill",
          verticalAlign: "center",
          margin: "auto 5px auto 5px",
          left: 0,
        }}
      />
      <div style={{ borderLeft: "1px solid", borderColor: "#bbbbbb" }} />
      <CardContent>
        <Typography
          component="h5"
          variant="h5"
          style={{ color: "#f5f5f5", textDecoration: "underline" }}
        >
          {props.title}
        </Typography>
        <Typography component="p" variant="body1" style={{ color: "#e4e4e4" }}>
          {props.description}
        </Typography>
      </CardContent>
      <div
        style={{
          width: "50px",
          height: "50",
          verticalAlign: "center",
          margin: "auto 5px auto 2px",
          right: "5px",
          bottom: "0px",
        }}
      >
        <img src="static/github.svg" alt="Git" width="30px" height="30px" />
      </div>
    </Card>
  );
}
