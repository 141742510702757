import { Card, CardContent, Typography } from "@material-ui/core";

interface Props {
  heading: string;
  text: JSX.Element;
  color: string;
}

export default function AboutMeItem(props: Props) {
  return (
    <Card
      variant="elevation"
      elevation={10}
      style={{
        border: "2px solid",
        borderColor: props.color,
        borderRadius: "5px",
        background: "#0000008b",
        marginTop: "20px",
        marginBottom: "10px",
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="h5"
          style={{
            color: "white",
            marginBottom: "10px",
            fontFamily: "Urbanist",
          }}
        >
          {props.heading}
        </Typography>
        {props.text}
      </CardContent>
    </Card>
  );
}
