import { GeneralRender } from "./Renderer";
import * as THREE from "three";
import { waveFragmentShader, waveVertexShader } from "../util/shaders";
import { useEffect, useRef } from "react";

class WaveRender {
  readonly meshRenderCount = 4;
  private segmentCount = 50;
  private segmentGeometry: Array<THREE.PlaneBufferGeometry> | null = null;
  private wave_uniform: any;
  private prev_time = 0;
  private rendererInstance: GeneralRender;

  constructor(renderInstance: GeneralRender) {
    this.rendererInstance = renderInstance;
    this.prev_time = Date.now();
    this.initWave();
  }

  private initWave = () => {
    this.rendererInstance.resetScene();
    this.rendererInstance.getCamera().fov = 75;
    this.rendererInstance.getCamera().updateProjectionMatrix();

    this.segmentGeometry = [];
    this.wave_uniform = [];
    // Draw multiple instances of the mesh with different colors and
    // slightly shifted time.
    const colors = [
      // Gray
      new THREE.Vector3(0.843, 0.667, 0.437),
      // Red
      // new THREE.Vector3(0.57, 0.09, 0.32),
      new THREE.Vector3(0.906, 0.062, 0.382),
      // Green
      // new THREE.Vector3(0.24, 0.49, 0.02),
      new THREE.Vector3(0.285, 0.796, 0.539),
      // Blue
      // new THREE.Vector3(0.14, 0.41, 0.7),
      new THREE.Vector3(0.06, 0.425, 0.906),
    ];
    for (let i = 0; i < this.meshRenderCount; i++) {
      this.segmentGeometry[i] = new THREE.PlaneBufferGeometry(
        400,
        300,
        this.segmentCount,
        this.segmentCount
      );
      let timeShift = i === 0 ? 0 : i / 100.0;
      if (i === 2) timeShift *= -1;
      this.wave_uniform[i] = {
        u_random: { value: 0 },
        u_amplitude: { value: 15 },
        u_frequency: { value: 0.05 },
        u_time: { value: timeShift },
        u_color: { value: colors[i] },
        u_resolution_height: { value: window.innerHeight },
      };

      const segmentMaterial = new THREE.ShaderMaterial({
        transparent: true,
        uniforms: this.wave_uniform[i],
        vertexShader: waveVertexShader,
        fragmentShader: waveFragmentShader,
        side: THREE.DoubleSide,
        wireframe: true,
      });

      const mesh = new THREE.Mesh(this.segmentGeometry[i], segmentMaterial);
      // Rotate mesh to get sideways view instead of top-down.
      mesh.rotation.x = 1.84;
      this.rendererInstance.getScene().add(mesh);
    }
    window.addEventListener("resize", this.updateWindowSize);
  };

  private updateWindowSize = () => {
    for (let i = 0; i < this.meshRenderCount; i++)
      this.wave_uniform[i].u_resolution_height = {
        value: window.innerHeight,
      };
  };

  private animate = () => {
    this.rendererInstance.updateAnimationFrameId(
      requestAnimationFrame(this.animate)
    );
    const delta = Date.now() - this.prev_time;
    for (let i = 0; i < this.meshRenderCount; i++)
      this.wave_uniform[i].u_time.value += delta / 4000;
    this.prev_time = Date.now();

    this.rendererInstance
      .getRenderer()
      .render(
        this.rendererInstance.getScene(),
        this.rendererInstance.getCamera()
      );
  };

  startRendering = (container: HTMLDivElement) => {
    container.appendChild(this.rendererInstance.getRenderer().domElement);
    this.animate();
  };
}

interface Props {
  renderer: GeneralRender;
}

export default function WaveRenderInstance(props: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const waveRendererRef = useRef<WaveRender | undefined>(undefined);

  useEffect(() => {
    if (waveRendererRef.current === undefined) {
      waveRendererRef.current = new WaveRender(props.renderer);
      waveRendererRef.current.startRendering(containerRef.current!);
    }
  }, [props.renderer]);

  return (
    <div ref={containerRef} style={{ position: "fixed", top: 0, zIndex: 0 }} />
  );
}
