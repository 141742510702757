import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import styled from "styled-components";

import AboutMeItem from "../components/AboutMeItem";
import LanguageButton, { Language } from "../components/LanguageButton";
import RenderInstance from "../renderer/Renderer";
import StarBeamRenderInstance from "../renderer/StarBeamRenderer";
import {
  END_DE,
  END_EN,
  INTERESTS_DE,
  INTERESTS_EN,
  MOTIVATION_DE,
  MOTIVATION_EN,
  PHILOSOPHY_DE,
  PHILOSOPHY_EN,
  SPARE_TIME_DE,
  SPARE_TIME_EN,
} from "../util/AboutMeText";

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #414141;
  background: -moz-linear-gradient(
    top,
    #270e61 0%,
    #04465a 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #270e61 0%,
    #04465a 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #270e61 0%,
    #04465a 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
`;

interface Props {
  navigationBar: JSX.Element;
  renderer: RenderInstance;
}
export default function AboutMeView(props: Props) {
  const [language, setLanguage] = useState<Language>("English");
  const [hoverCV, setHoverCV] = useState<boolean>(false);
  return (
    <Background>
      <div style={{ position: "fixed" }}>
        <StarBeamRenderInstance renderer={props.renderer.getRenderInstance()} />
        {props.navigationBar}
      </div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          position: "absolute",
          marginTop: "250px",
          overflow: "auto",
          maxHeight: "calc(100% - 250px)",
        }}
      >
        <Grid item xs={11}>
          <AboutMeItem
            heading="#Motivation and Education"
            text={
              language === "English" ? <MOTIVATION_EN /> : <MOTIVATION_DE />
            }
            color="#005a26"
          />
        </Grid>
        <Grid item xs={11}>
          <AboutMeItem
            heading="#Interests"
            text={language === "English" ? <INTERESTS_EN /> : <INTERESTS_DE />}
            color="#00325a"
          />
        </Grid>
        <Grid item xs={11}>
          <AboutMeItem
            heading="#Spare Time"
            text={
              language === "English" ? <SPARE_TIME_EN /> : <SPARE_TIME_DE />
            }
            color="#5a0038"
          />
        </Grid>
        <Grid item xs={11}>
          <AboutMeItem
            heading="#Philosophy"
            text={
              language === "English" ? <PHILOSOPHY_EN /> : <PHILOSOPHY_DE />
            }
            color="#5a3000"
          />
        </Grid>
        <Grid item xs={11}>
          <AboutMeItem
            heading="#End"
            text={language === "English" ? <END_EN /> : <END_DE />}
            color="#005a53"
          />
        </Grid>
        <Grid item xs={4}>
          <Card
            style={{
              border: "1px solid",
              borderColor: "#adadad",
              borderRadius: "5px",
              background: hoverCV ? "#b3b3b360" : "#ffffff10",
              marginTop: "20px",
              marginBottom: "50px",
              cursor: "pointer",
              padding: "0px",
            }}
            onMouseOver={() => setHoverCV(true)}
            onMouseLeave={() => setHoverCV(false)}
            onClick={() => window.open(`static/cv.pdf`)}
          >
            <CardContent style={{ padding: "10px" }}>
              <Typography
                component="h5"
                variant="h5"
                style={{
                  color: "#f5f5f5",
                  fontFamily: "Urbanist",
                  fontSize: "18px",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                Show CV
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <LanguageButton currentLanguage={language} setLanguage={setLanguage} />
    </Background>
  );
}
