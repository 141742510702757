import { useRef, useEffect } from "react";
import { GeneralRender } from "./Renderer";
import * as THREE from "three";

class FlowRender {
  readonly particleCount = 15000;
  private plane: THREE.InstancedMesh | undefined = undefined;
  private offset: Array<THREE.Vector3> = [];
  private isInitialized = false;
  private initIdx = 0;

  private rendererInstance: GeneralRender;

  constructor(renderInstance: GeneralRender) {
    this.rendererInstance = renderInstance;
    this.initFlow();
  }

  private initFlow = () => {
    this.rendererInstance.resetScene();

    const particleGemoetry = new THREE.BoxBufferGeometry(1, 1, 1);

    const particleMaterial = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      side: THREE.FrontSide,
      shininess: 100,
      specular: 0xffffff,
    });

    this.plane = new THREE.InstancedMesh(
      particleGemoetry,
      particleMaterial,
      this.particleCount
    );
    this.rendererInstance.getScene().add(this.plane);

    const drawObj = new THREE.Object3D();
    for (let i = 0; i < this.particleCount; i++) {
      const offsetAngle = Math.random() * 360;
      const directionVector = new THREE.Vector3(
        1.0,
        Math.cos(offsetAngle),
        Math.sin(offsetAngle)
      ).multiplyScalar(Math.random() * 0.05);
      this.offset.push(directionVector);
      drawObj.position.set(-200.0, -15.0, 0.0);
      drawObj.scale.set(0.4, 0.4, 0.4);
      drawObj.position.add(this.offset[i]);

      // Set initial position of particles to avoid startup
      const initPosXOffset = Math.random() * 300;
      drawObj.position.x += initPosXOffset;
      drawObj.position.y += Math.cos(drawObj.position.x / 40) * 30;
      drawObj.position.z += initPosXOffset / 3.0;

      drawObj.updateMatrix();
      this.plane.setMatrixAt(i, drawObj.matrix);
    }
    this.plane.instanceMatrix.needsUpdate = true;

    const lights: Array<THREE.PointLight> = [];
    lights[0] = new THREE.PointLight(0x22aa00, 1, 1000, 5);
    lights[0].position.set(-150, 0, 50);
    lights[1] = new THREE.PointLight(0x002fff, 1, 1000, 1);
    lights[1].position.set(-60, -30, 110);
    lights[2] = new THREE.PointLight(0xce0000, 0.8, 1000, 5);
    lights[2].position.set(50, 10, 150);
    lights[3] = new THREE.PointLight(0xff9100, 0.8, 1000, 2);
    lights[3].position.set(100, -20, 150);
    lights.forEach((light) => {
      this.rendererInstance.getScene().add(light);
    });

    this.rendererInstance.renderScene();
  };

  private animate = () => {
    this.rendererInstance.updateAnimationFrameId(
      requestAnimationFrame(this.animate)
    );

    let iterations = this.particleCount;
    if (!this.isInitialized) {
      this.initIdx += 20;
      iterations = Math.min(this.initIdx, this.particleCount);
      if (this.initIdx === this.particleCount) this.isInitialized = true;
    }

    const matrix = new THREE.Matrix4();
    for (let i = 0; i < iterations; i++) {
      this.plane!.getMatrixAt(i, matrix);
      const pos = new THREE.Vector3();
      pos.setFromMatrixPosition(matrix);
      pos.x += 0.3;
      if (pos.x < -35) pos.y += (pos.x + 100) / 200.0;
      else if (pos.x < 0) pos.y += (-pos.x + 30) / 200.0;
      else if (pos.x < 30) pos.y += (30 - pos.x) / 200.0;
      else pos.y += -(pos.x - 30) / 200.0;
      pos.z += (-Math.abs(pos.x) + 200) * 0.0015;
      pos.add(this.offset[i]);
      if (pos.x > 100) {
        const initOffset = this.offset[i].clone();
        pos.set(-200.0, -15.0, 0.0).add(initOffset.multiplyScalar(10.0));
      }
      matrix.setPosition(pos);
      this.plane!.setMatrixAt(i, matrix);
    }
    this.plane!.instanceMatrix.needsUpdate = true;

    this.rendererInstance.renderScene();
  };

  startRendering = (container: HTMLDivElement) => {
    container.appendChild(this.rendererInstance.getRenderer().domElement);
    this.animate();
  };
}

interface Props {
  renderer: GeneralRender;
}

export default function FlowRenderInstance(props: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const flowRenderRef = useRef<FlowRender | undefined>(undefined);

  useEffect(() => {
    if (flowRenderRef.current === undefined) {
      flowRenderRef.current = new FlowRender(props.renderer);
      flowRenderRef.current.startRendering(containerRef.current!);
    }
  }, [props.renderer]);

  return <div ref={containerRef} style={{ zIndex: 0, position: "absolute" }} />;
}
