import { useEffect, useRef } from "react";
import { GeneralRender } from "./Renderer";
import * as THREE from "three";

class PlanetRender {
  readonly planetParticleCount = 1000;
  private planetCage: THREE.Object3D | undefined = undefined;
  private planetObject: THREE.Object3D | undefined = undefined;
  private planetParticles: THREE.Object3D | undefined = undefined;

  private rendererInstance: GeneralRender;

  constructor(renderInstance: GeneralRender) {
    this.rendererInstance = renderInstance;
    this.initPlanet();
  }

  private initPlanet = () => {
    this.rendererInstance.resetScene();

    this.planetCage = new THREE.Object3D();
    this.planetObject = new THREE.Object3D();
    this.planetParticles = new THREE.Object3D();

    this.rendererInstance.getScene().add(this.planetCage);
    this.rendererInstance.getScene().add(this.planetObject);
    this.rendererInstance.getScene().add(this.planetParticles);

    const planetCageGeometry = new THREE.IcosahedronGeometry(70, 1);
    const planetGeometry = new THREE.IcosahedronGeometry(25, 1);
    const particleGeometry = new THREE.TetrahedronGeometry(1, 1);

    const material = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      flatShading: true,
    });

    for (let i = 0; i < this.planetParticleCount; i++) {
      const mesh = new THREE.Mesh(particleGeometry, material);
      mesh.position
        .set(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5)
        .normalize();
      mesh.position.multiplyScalar(90 + Math.random() * 700);
      mesh.rotation.set(
        Math.random() * 2,
        Math.random() * 2,
        Math.random() * 2
      );
      this.planetParticles.add(mesh);
    }

    const planetCageMaterial = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      wireframe: true,
      side: THREE.DoubleSide,
    });

    const planetMaterial = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      flatShading: true,
    });

    const planetCageMesh = new THREE.Mesh(
      planetCageGeometry,
      planetCageMaterial
    );
    this.planetCage.add(planetCageMesh);

    const planetMesh = new THREE.Mesh(planetGeometry, planetMaterial);
    this.planetObject.add(planetMesh);

    const ambientLight = new THREE.AmbientLight(0x999999);
    this.rendererInstance.getScene().add(ambientLight);

    const lights = [];
    lights[0] = new THREE.DirectionalLight(0xf7eaa3, 0.2);
    lights[0].position.set(1, 0, 0);
    lights[1] = new THREE.DirectionalLight(0x22cc69, 1);
    lights[1].position.set(0.5, 1, 0.75);
    lights[2] = new THREE.DirectionalLight(0xda0557, 0.5);
    lights[2].position.set(-0.5, -1, 0.5);
    this.rendererInstance.getScene().add(lights[0]);
    this.rendererInstance.getScene().add(lights[1]);
    this.rendererInstance.getScene().add(lights[2]);
  };

  private animate = () => {
    this.rendererInstance.updateAnimationFrameId(
      requestAnimationFrame(this.animate)
    );
    if (
      this.planetCage !== undefined &&
      this.planetObject !== undefined &&
      this.planetParticles !== undefined
    ) {
      this.planetCage.rotation.x -= 0.003;
      this.planetCage.rotation.y -= 0.004;
      this.planetObject.rotation.x += 0.003;
      this.planetObject.rotation.y += 0.004;
      this.planetParticles.rotation.x -= 0.001;
      this.planetParticles.rotation.y += 0.002;
    }
    this.rendererInstance.renderScene();
  };

  startRendering = (container: HTMLDivElement) => {
    container.appendChild(this.rendererInstance.getRenderer().domElement);
    this.animate();
  };
}

interface Props {
  renderer: GeneralRender;
}

export default function PlanetRenderInstance(props: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const planetRenderRef = useRef<PlanetRender | undefined>(undefined);

  useEffect(() => {
    if (planetRenderRef.current === undefined) {
      planetRenderRef.current = new PlanetRender(props.renderer);
      planetRenderRef.current.startRendering(containerRef.current!);
    }
  }, [props.renderer]);

  return <div ref={containerRef} style={{ zIndex: 0, position: "absolute" }} />;
}
