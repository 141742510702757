import { useCallback } from "react";
import styled from "styled-components";

import MenuButton from "../components/MenuButton";
import { ViewType } from "../views/ViewHandler";
import { Grid } from "@material-ui/core";

const NameHeading = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Montserrat;
  font-size: 3em;
  color: #c9c9c9;
  font-weight: 100;
  margin-top: 5%;
`;

interface Props {
  changeView: (newView: ViewType) => void;
}

export default function NavigationBar(props: Props) {
  const selectNewView = useCallback(
    (newView: ViewType) => {
      props.changeView(newView);
    },
    [props]
  );

  return (
    <div style={{ flexGrow: 1 }}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        style={{ position: "fixed", zIndex: 1 }}
      >
        <Grid item xs={12}>
          <NameHeading
            onClick={() => selectNewView("HOME")}
            style={{
              cursor: "pointer",
            }}
          >
            ROBERT SCHAFFENRATH
          </NameHeading>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          style={{ marginTop: "20px" }}
        >
          <Grid
            item
            xs={4}
            style={{ textAlign: "center", alignContent: "center" }}
          >
            <MenuButton
              text="ABOUT ME"
              targetLocation={() => selectNewView("ABOUT_ME")}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{ textAlign: "center", alignContent: "center" }}
          >
            <MenuButton
              text="PROJECTS"
              targetLocation={() => selectNewView("PROJECTS")}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{ textAlign: "center", alignContent: "center" }}
          >
            <MenuButton
              text="CONTACT"
              targetLocation={() => selectNewView("CONTACT")}
            />
            <MenuButton
              text="GALAXY"
              targetLocation={() => selectNewView("GALAXY")}
            />
            {/* <MenuButton
              text="GALAXY2"
              targetLocation={() => selectNewView("GALAXY2")}
            /> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
