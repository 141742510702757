import { useEffect, useRef } from "react";
import { GeneralRender } from "./Renderer";
import * as THREE from "three";

class StarBeamRender {
  readonly particleCount = 15000;
  private particleVel: Array<THREE.Vector3> = [];
  private plane: THREE.InstancedMesh | undefined = undefined;
  private isInitialized = false;
  private initIdx = 0;

  private rendererInstance: GeneralRender;
  constructor(renderInstance: GeneralRender) {
    this.rendererInstance = renderInstance;
    this.initStarBeam();
  }

  private initStarBeam = () => {
    this.rendererInstance.resetScene();
    this.rendererInstance.getCamera().fov = 70;
    this.rendererInstance.getCamera().position.set(0, 0, 0);
    this.rendererInstance.getCamera().rotateX(-0.6);
    this.rendererInstance.getCamera().position.z += 40;

    this.rendererInstance.getCamera().updateProjectionMatrix();

    const particleGeometry = new THREE.OctahedronBufferGeometry(0.7, 1);

    const particleColors: Array<THREE.Color> = [
      new THREE.Color(0x78aeec),
      new THREE.Color(0x3fdd86),
    ];

    const particleMaterial = new THREE.PointsMaterial({
      color: particleColors[0],
      size: 1,
      transparent: true,
      opacity: 1.0,
      depthTest: false,
      blending: THREE.AdditiveBlending,
    });

    this.plane = new THREE.InstancedMesh(
      particleGeometry,
      particleMaterial,
      this.particleCount
    );

    this.rendererInstance.getScene().add(this.plane);

    const drawObj = new THREE.Object3D();
    drawObj.scale.set(0.1, 0.1, 0.1);

    let colorIdx = 0;
    for (let i = 0; i < this.particleCount; i++) {
      // Set init position with y-offset due to easier camera positioning
      drawObj.position.set(0.0, -70.0, 0.0);
      // To distribute particles uniformly create a random direction vector
      const angle = Math.random() * 360;
      const velVector = new THREE.Vector3(
        Math.cos(angle),
        1.0,
        Math.sin(angle)
      );

      // Change direction vector to velocity vector by multiplying with force
      const distributionLottery = Math.random();
      let distributionFactor = 0.0;
      if (distributionLottery < 0.4) distributionFactor = 0.01;
      else if (distributionLottery < 0.6) distributionFactor = 0.03;
      else if (distributionLottery < 0.9) distributionFactor = 0.05;
      else distributionFactor = 0.08;
      distributionFactor += Math.random() * 0.02 - 0.01;
      velVector.multiplyScalar(Math.random() * distributionFactor);
      velVector.y = Math.random() * 0.01 + 0.1;

      // Set initial position of particles to avoid startup
      const initTimeOffset = Math.random() * 1000;
      const tmpVel = velVector.clone().multiplyScalar(initTimeOffset);
      drawObj.position.set(tmpVel.x, Math.random() * 70 - 70, tmpVel.z);

      velVector.multiplyScalar(0.2);
      this.particleVel.push(velVector);

      drawObj.updateMatrix();
      this.plane.setMatrixAt(i, drawObj.matrix);
      // Alternate particle color
      this.plane.setColorAt(i, particleColors[colorIdx++]);
      if (colorIdx === 2) colorIdx = 0;
    }

    this.plane.instanceMatrix.needsUpdate = true;

    this.rendererInstance.renderScene();
  };

  private animate = () => {
    this.rendererInstance.updateAnimationFrameId(
      requestAnimationFrame(this.animate)
    );

    const matrix = new THREE.Matrix4();
    for (let i = 0; i < this.particleCount; i++) {
      this.plane!.getMatrixAt(i, matrix);
      const pos = new THREE.Vector3();
      pos.setFromMatrixPosition(matrix);
      pos.add(this.particleVel[i]);
      if (pos.y > 1) pos.set(0.0, -70.0, 0.0);
      matrix.setPosition(pos);
      this.plane!.setMatrixAt(i, matrix);
    }
    this.plane!.rotation.y += 0.002;
    this.plane!.instanceMatrix.needsUpdate = true;

    this.rendererInstance.renderScene();
  };

  startRendering = (container: HTMLDivElement) => {
    container.appendChild(this.rendererInstance.getRenderer().domElement);
    this.animate();
  };
}

interface Props {
  renderer: GeneralRender;
}

export default function StarBeamRenderInstance(props: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const waveRendererRef = useRef<StarBeamRender | undefined>(undefined);

  useEffect(() => {
    if (waveRendererRef.current === undefined) {
      waveRendererRef.current = new StarBeamRender(props.renderer);
      waveRendererRef.current.startRendering(containerRef.current!);
    }
  }, [props.renderer]);

  return <div ref={containerRef} style={{ position: "fixed", top: 0 }} />;
}
