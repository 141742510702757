import { Grid } from "@material-ui/core";
import styled from "styled-components";
import ProjectItem from "../components/ProjectItem";
import FlowRenderInstance from "../renderer/FlowRenderer";
import RenderInstance from "../renderer/Renderer";

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #414141;
  background: -moz-linear-gradient(
    top,
    #001f0d 0%,
    #003f22 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #001f0d 0%,
    #003f22 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #001f0d 0%,
    #003f22 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
`;

interface Props {
  navigationBar: JSX.Element;
  renderer: RenderInstance;
}
export default function ProjectView(props: Props) {
  return (
    <Background>
      <div style={{ position: "fixed" }}>
        <FlowRenderInstance renderer={props.renderer.getRenderInstance()} />
        {props.navigationBar}
      </div>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="baseline"
        style={{
          position: "absolute",
          marginTop: "250px",
          overflow: "auto",
          maxHeight: "calc(100% - 250px)",
        }}
      >
        <Grid item xs={5}>
          <ProjectItem
            color="#354d74e3"
            title="This Homepage"
            description="A small side project just for fun to play around with three.js and get better with React. "
            image="static/pc.svg"
            link="https://gitlab.com/schaffenrath/homepage"
          />
        </Grid>
        <Grid item xs={5}>
          <ProjectItem
            color="#354d74e3"
            title="Benchmark for Performance Evaluation of SHACL Implementations in Graph Databases"
            description="This was a semester project in Semantic Web. The paper was published at the RuleML conference and awarded the best student paper award."
            image="static/world.svg"
            link="https://gitlab.com/schaffenrath/shacl-benchmark"
          />
        </Grid>
        <Grid item xs={5}>
          <ProjectItem
            color="#354d74e3"
            title="OS Simulator"
            description="My bacherlor's thesis simulating some operating system services with a nice visualization, created with the Qt framework."
            image="static/os.svg"
            link="https://gitlab.com/schaffenrath/qt"
          />
        </Grid>
        <Grid item xs={5}>
          <ProjectItem
            color="#354d74e3"
            title="A Performance Analysis Tool for Celerity"
            description="My master's thesis capturing and analyzing events of the Celerity runtime, visualized with React and three.js."
            image="static/celerity.png"
            link="https://gitlab.com/schaffenrath/celerity-cpat"
          />
        </Grid>
        <Grid item xs={5}>
          <ProjectItem
            color="#354d74e3"
            title="Hotspot"
            description="A semester project that is build as a web service, crawling data from Facebook to highlight interesting places on a map."
            image="static/pin.svg"
            link="https://gitlab.com/onixion/hotspot"
          />
        </Grid>
        <Grid item xs={5}>
          <ProjectItem
            color="#354d74e3"
            title="Powercontrol"
            description="A small Android application that communicates with a server on a Raspberry Pi, allowing to control the GPIO pins to configure LED strips."
            image="static/lightning.svg"
            link="https://gitlab.com/schaffenrath/powercontrol"
          />
        </Grid>
        <Grid item xs={5}>
          <ProjectItem
            color="#354d74e3"
            title="Angle Dust - rogue-like dungeon crawler"
            description="A semester project where we had so develop a small game within four months, while utilizing features of the C++17 standard."
            image="static/controller.svg"
            link="https://gitlab.com/schaffenrath/angel-dust-game"
          />
        </Grid>
        <Grid item xs={5}>
          <ProjectItem
            color="#354d74e3"
            title="mC Compiler"
            description="A very basic compiler developed for the Compiler Construction course, allowing to compile a C-like language to assembly. "
            image="static/compiler.svg"
            link="https://gitlab.com/schaffenrath/mc-compiler"
          />
        </Grid>
        <Grid item xs={5}>
          <ProjectItem
            color="#354d74e3"
            title="Image tampering detection software"
            description="An Python algorithm detecting areas of images that have likely been manipulated, created as a semester project. "
            image="static/image.svg"
            link="https://gitlab.com/schaffenrath/image-forensic"
          />
        </Grid>
        <Grid item xs={5}>
          <ProjectItem
            color="#354d74e3"
            title="2D Lava Lamp Simulation"
            description="A 2D fluid simulation representing a lava lamp written in C++ and with WebGL for the Physical Based Simulations course."
            image="static/lava.svg"
            link="https://gitlab.com/schaffenrath/lava-lamp"
          />
        </Grid>
        <Grid item xs={5}>
          <ProjectItem
            color="#354d74e3"
            title="ABC Kinderkrippe"
            description="A day nursery administration software build from the ground up for the Software Development and Project Management course, build with the Spring framework."
            image="static/abc.svg"
            link="https://gitlab.com/schaffenrath/abc-kinderkrippe"
          />
        </Grid>
        <Grid item xs={5}>
          <ProjectItem
            color="#72344ce4"
            title="SMARD"
            description="A day time management system build from the ground up for the Development of Software Systems course build with the Spring framework."
            image="static/clock.svg"
            link="https://gitlab.com/schaffenrath/smard"
          />
        </Grid>
      </Grid>
    </Background>
  );
}
