
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import RenderInstance from "../renderer/Renderer";
import GalaxyRenderInstance from "../renderer/GalaxyRenderer";

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #414141;
  background: -moz-linear-gradient(
    top,
    #414141 0%,
    #152924 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #414141 0%,
    #152929 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #414141 0%,
    #152929 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
`;

interface Props {
    navigationBar: JSX.Element;
    renderer: RenderInstance;
}

export default function HomeView(props: Props) {
    return (
        <Background>
            <GalaxyRenderInstance renderer={props.renderer.getRenderInstance()} />
            {props.navigationBar}
            <Typography
                variant="h5"
                component="h2"
                style={{
                    fontFamily: "Urbanist",
                    position: "fixed",
                    marginTop: "300px",
                    width: "100%",
                    textAlign: "center",
                    textDecoration: "underline",
                    color: "#ffffffc1",
                    fontSize: "4vw",
                }}
            ></Typography>
        </Background>
    );
}
