import { useEffect, useRef } from "react";
import { GeneralRender } from "./Renderer";
import * as THREE from "three";

class GalaxyRender {
    readonly particleCount = 15000;
    private particleVel: Array<THREE.Vector3> = [];
    private plane: THREE.InstancedMesh | undefined = undefined;
    private isInitialized = false;
    private initIdx = 0;

    private rendererInstance: GeneralRender;
    constructor(renderInstance: GeneralRender) {
        this.rendererInstance = renderInstance;
        this.initStarBeam();
    }

    private initStarBeam = () => {
        this.rendererInstance.resetScene();
        this.rendererInstance.getCamera().fov = 70;
        this.rendererInstance.getCamera().position.set(0, 0, 0);
        this.rendererInstance.getCamera().rotateX(-0.4);
        this.rendererInstance.getCamera().position.z += 150;

        this.rendererInstance.getCamera().updateProjectionMatrix();

        const particleGeometry = new THREE.SphereBufferGeometry(1, 8, 8);

        const particleColors: Array<THREE.Color> = [
            new THREE.Color(0x78aeec),
            new THREE.Color(0x3fdd86),
        ];

        const particleMaterial = new THREE.PointsMaterial({
            color: particleColors[0],
            size: 1,
            transparent: true,
            opacity: 1.0,
            depthTest: false,
            blending: THREE.AdditiveBlending,
        });

        this.plane = new THREE.InstancedMesh(
            particleGeometry,
            particleMaterial,
            this.particleCount
        );

        this.rendererInstance.getScene().add(this.plane);

        const drawObj = new THREE.Object3D();
        drawObj.scale.set(0.1, 0.1, 0.1);

        let colorIdx = 0;
        for (let i = 0; i < this.particleCount; i++) {
            // Set init position with y-offset due to easier camera positioning
            drawObj.position.set(0.0, -90.0, 0.0);
            // To distribute particles uniformly create a random direction vector
            const angle = Math.random() * 360;
            const velVector = new THREE.Vector3(
                Math.cos(angle),
                1.0,
                Math.sin(angle)
            );

            velVector.multiplyScalar(Math.random() * 100);
            velVector.setY(-60.0 + (Math.random() * 10 - 5));
            drawObj.position.set(velVector.x, velVector.y, velVector.z);


            drawObj.updateMatrix();
            this.plane.setMatrixAt(i, drawObj.matrix);
            // Alternate particle color
            this.plane.setColorAt(i, particleColors[colorIdx++]);
            if (colorIdx === 2) colorIdx = 0;
        }

        this.plane.instanceMatrix.needsUpdate = true;

        this.rendererInstance.renderScene();
    };

    private animate = () => {
        this.rendererInstance.updateAnimationFrameId(
            requestAnimationFrame(this.animate)
        );

        const matrix = new THREE.Matrix4();
        for (let i = 0; i < this.particleCount; i++) {
            this.plane!.getMatrixAt(i, matrix);
            const pos = new THREE.Vector3();
            pos.setFromMatrixPosition(matrix);
            // pos.add(this.particleVel[i]);
            // if (pos.y > 1) pos.set(0.0, -70.0, 0.0);
            matrix.setPosition(pos);
            this.plane!.setMatrixAt(i, matrix);
        }
        this.plane!.rotation.y += 0.002;
        this.plane!.instanceMatrix.needsUpdate = true;

        this.rendererInstance.renderScene();
    };

    startRendering = (container: HTMLDivElement) => {
        container.appendChild(this.rendererInstance.getRenderer().domElement);
        this.animate();
    };
}

interface Props {
    renderer: GeneralRender;
}

export default function GalaxyRenderInstance(props: Props) {
    const containerRef = useRef<HTMLDivElement>(null);
    const galaxyRendererRef = useRef<GalaxyRender | undefined>(undefined);

    useEffect(() => {
        if (galaxyRendererRef.current === undefined) {
            galaxyRendererRef.current = new GalaxyRender(props.renderer);
            galaxyRendererRef.current.startRendering(containerRef.current!);
        }
    }, [props.renderer]);

    return <div ref={containerRef} style={{ position: "fixed", top: 0 }} />;
}
